<template>
  <div class="list-box" v-loading.fullscreen.lock="loading">
    <div :class="['list-ops', showList ? 'close-list' : '']" @click="listOps">
      <i class="iconfont icon-zhankaishouqi"></i>
      <span>{{ showList ? "收起列表" : "展开列表" }}</span>
    </div>
    <div class="list-show" v-if="showList">
      <div class="title">
        <span class="text">全部设备</span>
        <span class="close-icon" @click="showList = false">
          <i class="el-icon-close"></i>
        </span>
      </div>
      <!-- 告警设备筛选条件 -->
      <div class="alarm-search" v-show="activeStatus === 'alarm'">
        <div class="alarm-type">
          <gc-custom-search
            keyWord="告警类型"
            width="264px"
            type="select"
            :searchOption="alarmTypeOptions"
            :searchOptionKey="{ label: 'name', value: 'id' }"
            :search.sync="alarmTypeSearch"
            :selectClearable="true"
          ></gc-custom-search>
        </div>
        <div class="time-search">
          <span
            class="time-search-item"
            :class="{
              active: alarmCreateTimeFlag == 1,
            }"
            @click="handleAlarmData(1)"
            >今日</span
          >
          <span
            class="time-search-item"
            :class="{
              active: alarmCreateTimeFlag == 0,
            }"
            @click="handleAlarmData(0)"
            >往期</span
          >
        </div>
      </div>
      <div class="content" v-if="list.length > 0">
        <div
          class="list-item"
          v-for="item in list"
          :key="item.deviceId"
          @click="changeLocation(item)"
        >
          <div class="list-item-top">
            <div class="device-name">
              <div class="device-name-left">
                <div class="icon-box">
                  <i
                    class="iconfont"
                    :class="[transIcon(item.firstCategory)]"
                  ></i>
                </div>
                <span class="name">{{
                  !isBlank(item.deviceName) ? item.deviceName : "--"
                }}</span>
              </div>
              <div class="device-name-right">
                <span class="time">{{
                  !isBlank(item.createTime) ? item.createTime : "--"
                }}</span>
                <i
                  class="el-icon-arrow-right"
                  @click="(e) => jumpDetail(e, item)"
                ></i>
              </div>
            </div>
            <div class="spec-info">
              <div class="info-item">
                <i class="iconfont icon-shebeibianhao1"></i>
                <span class="value">{{
                  !isBlank(item.deviceNo) ? item.deviceNo : "--"
                }}</span>
              </div>
              <div class="info-item">
                <i class="iconfont icon-zhandianmingcheng"></i>
                <span class="value">{{
                  !isBlank(item.userName) ? item.userName : "--"
                }}</span>
              </div>
              <div class="info-item">
                <i class="iconfont icon-shebeidizhi"></i>
                <span class="value">{{
                  !isBlank(item.address) ? item.address : "--"
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="list-item-bottom"
            v-if="item.alarmTags && item.alarmTags.length > 0"
          >
            <span
              class="alarm-tag"
              v-for="el in item.alarmTags"
              :key="el.alarmId"
              :style="{
                background: activeColorObj[item.runningState],
              }"
              >{{ el.alarmName }}</span
            >
          </div>
        </div>
      </div>
      <gc-empty v-else></gc-empty>
      <div class="pagenation-box" v-if="list.length > 0">
        <gc-pagination
          :total="page.total"
          :page-size="page.size"
          :currentPage="page.current"
          :page-params="pageParams"
          @current-page-change="currentPageChange"
          style="padding: 10px 20px"
        ></gc-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetDeviceList, apiGetAlarmType } from "@/api/runBoard.js";
import { isBlank } from "@/utils/validate.js";
import { activeColorObj } from "../const.js";
import { mapGetters } from "vuex";

export default {
  name: "List",
  props: {
    activeTab: {
      type: String,
      default: "0",
    },
    activeStatus: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      page: {
        total: 0,
        size: 10,
        current: 1,
      },
      pageParams: {
        currentPage: 1,
        hasUp: false,
        hasNext: true,
      },
      showList: true,
      list: [],
      activeColorObj,
      alarmCreateTimeFlag: 1, //0 往期 1 今日
      alarmTypeOptions: [], //告警类型下拉字典
      alarmTypeSearch: "", //告警类型
      loading: false,
    };
  },
  watch: {
    activeTab() {
      this.showList = true;
      this.list = [];
      this.updateDeviceList(1, true);
    },
    activeStatus(newVal) {
      this.showList = true;
      this.list = [];
      if (newVal === "alarm") {
        // 切换至告警则重置筛选条件为空
        this.alarmTypeSearch = "";
        this.alarmCreateTimeFlag = 1;
      }
      this.updateDeviceList(1, true);
    },
    alarmTypeSearch() {
      this.updateDeviceList(1, true);
    },
  },

  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },

  mounted() {
    // 默认请求综合监控的全部设备
    // this.getDeviceList(1).then((res) => {
    //   this.$emit("initList", res);
    // });
    // 告警类型字典
    apiGetAlarmType().then((res) => {
      this.alarmTypeOptions = res || [];
    });
  },

  methods: {
    isBlank,
    // 展开收起列表
    listOps() {
      this.showList = !this.showList;
    },
    // 请求设备列表
    getDeviceList(current, params = {}) {
      const tenantType = this.userInfo.tenantType;
      return new Promise((resolve) => {
        this.loading = true;
        this.page.current = current;
        // 上方输入框查询参数
        const { keyWord, search } = this.$parent.$parent.$refs.search;
        let obj = {
          size: 10,
          current: this.page.current,
          firstCategory: tenantType === "0" ? this.activeTab : undefined,
          userTypeId: tenantType === "1" ? this.activeTab : undefined,
          runningState: this.activeStatus,
          [keyWord]: search,
        };
        //告警状态
        if (this.activeStatus === "alarm") {
          obj["alarmCreateTimeFlag"] = this.alarmCreateTimeFlag;
          obj["alarmType"] = this.alarmTypeSearch;
        }
        apiGetDeviceList({
          ...obj,
          ...params,
        })
          .then((res) => {
            const { total, records } = res || {};
            this.list = records || [];
            this.page.total = total;
            resolve(this.list);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 翻页
    currentPageChange(current) {
      this.page.current = current;
      this.updateDeviceList(current);
    },
    updateDeviceList(current, isClear, params = {}) {
      this.getDeviceList(current, params).then((res) => {
        if (!this.$parent.$parent.$refs.map.AMap) {
          this.$emit("initList", res);
        } else {
          this.$parent.$parent.$refs.map.renderMarker(res, isClear);
        }
      });
    },
    // 告警设备 今日/往期切换
    handleAlarmData(value) {
      this.alarmCreateTimeFlag = value;
      this.updateDeviceList(1, true);
    },
    // 点击列表中的设备定位至地图中心
    changeLocation(item) {
      if (!(!isBlank(item.longitude) && !isBlank(item.latitude))) {
        this.$message.warning("请完善设备安装经纬度信息!");
      } else {
        this.$parent.$parent.$refs.map.setDeviceLocation(item);
      }
    },
    // 设备icon转换
    transIcon(code) {
      return (
        (
          (this?.$parent?.$parent.$refs?.category?.categoryTab || []).find(
            (item) => item.code === code
          ) || {}
        ).icon || "icon-zonghejiankong"
      );
    },
    // 跳转设备详情
    jumpDetail(e, item) {
      e.stopPropagation();
      this.$router.push({
        name: "deviceDetail",
        query: {
          deviceId: item.deviceId,
          deviceType: item.deviceTypeId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-box {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .list-ops {
    width: 100px;
    height: 48px;
    line-height: 48px;
    background: #4d6bff;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 20px;
    i {
      font-size: 14px;
    }
    span {
      padding-left: 5px;
    }
  }
  .close-list {
    i {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  .list-show {
    width: 416px;
    height: calc(100vh - 200px);
    background: #e7e8f2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    .title {
      height: 36px;
      background: rgba(110, 133, 255, 0.95);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #fff;
      .close-icon {
        cursor: pointer;
      }
    }
    .alarm-search {
      padding: 12px;
      display: flex;
      .gc-custom-search {
        background: #fff;
      }
      .time-search {
        padding-left: 10px;
        display: flex;
        &-item {
          width: 57px;
          height: 100%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        &-item.active {
          background: #7b90fe;
          color: #fff;
        }
        &-item:nth-child(1) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &-item:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
    .content {
      flex: 1;
      height: 0;
      overflow: auto;
      padding: 0 12px 12px 12px;

      .list-item {
        background: #fff;
        border-radius: 3px;
        padding: 10px 12px 0 12px;
        cursor: pointer;
        &-top {
          padding-bottom: 16px;
          .device-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-left {
              display: flex;
              align-items: center;
            }
            .icon-box {
              width: 25px;
              height: 25px;
              line-height: 25px;
              background: #eaedff;
              border-radius: 6px;
              text-align: center;
              i {
                font-size: 18px;
                color: #4d6bff;
              }
            }
            .name {
              padding-left: 7px;
              font-size: 16px;
              font-weight: 600;
              color: #333333;
            }
            .device-name-right {
              flex-shrink: 0;
              .time {
                font-size: 12px;
                color: #969cbb;
              }
              i {
                cursor: pointer;
                padding: 5px 5px 5px 10px;
                color: #6b76ac;
              }
            }
          }
          .spec-info {
            padding-left: 32px;
            .info-item {
              display: flex;
              align-items: flex-start;
              padding-top: 10px;
              vertical-align: middle;
              i {
                font-size: 14px;
                color: #6b7399;
              }
              .value {
                display: inline-block;
                padding-left: 5px;
                color: #5c6073;
              }
            }
          }
        }
        &-bottom {
          padding: 0 12px 10px 32px;
          border-top: 1px solid #cccccc;
          .alarm-tag {
            display: inline-block;
            color: #fff;
            border-radius: 15px;
            padding: 5px;
            margin: 10px 10px 0 0;
          }
        }
      }
      .list-item + .list-item {
        margin-top: 12px;
      }
    }
  }
}
::-webkit-scrollbar-track-piece {
  background: #e7e8f2 !important;
}
</style>

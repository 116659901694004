<template>
  <div class="run-board">
    <!-- 地图 -->
    <map-bg ref="map"></map-bg>
    <!-- 搜索框 -->
    <search ref="search"></search>
    <!-- 设备类别 -->
    <category-tab ref="category" @getInitView="getInitView"></category-tab>
  </div>
</template>

<script>
import MapBg from "./components/MapBg.vue";
import Search from "./components/Search.vue";
import CategoryTab from "./components/CategoryTab.vue";

export default {
  name: "runBoard",
  components: { MapBg, Search, CategoryTab },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    getInitView(list) {
      this.$refs.map.initAMap(list);
    },
  },
};
</script>
<style lang="scss" scoped>
.run-board {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>

import request from "./request";

// 查询设备列表
export const apiGetCategoryAndStatus = (params) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getFirstCategoryAndStatus",
    method: "GET",
    params,
    pending: true,
  });
};

// 获取设备数量
export const apiGetDeviceNum = (params) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getRunningStateCount",
    method: "GET",
    params,
    pending: true,
  });
};

export const apiGetWaterRunningStateCount = (params) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getWaterRunningStateCount",
    method: "GET",
    params,
    pending: true,
  });
};

// 获取设备列表
export const apiGetDeviceList = (data) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getDeviceList",
    method: "POST",
    data,
    pending: true,
  });
};

// 获取视野范围内坐标
export const apiGetViewDeviceList = (data) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getDeviceGisTag",
    method: "POST",
    data,
    pending: true,
  });
};

// 获取视野范围内坐标
export const apiGetAlarmType = (data) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getAlarmTypes",
    method: "GET",
    data,
    pending: true,
  });
};

// 获取设备详情
export const apiGetDeviceDetail = (params) => {
  return request({
    url: "/monitor/operationManagement/digitalSystemBoard/getDeviceDetail",
    method: "GET",
    params,
    pending: true,
  });
};

<template>
  <div class="device-category-search">
    <div class="category-tab">
      <div class="first-category">
        <div
          class="first-category-item"
          v-for="item in categoryTab"
          :key="item.code"
          :class="{
            active: item.code == activeTab,
          }"
          @click="categoryClick(item)"
        >
          <div class="icon-box">
            <i class="iconfont" :class="item.icon"></i>
          </div>
          <span class="category-text">{{ item.name }}</span>
        </div>
      </div>
      <div class="device-status">
        <div
          class="device-status-item"
          v-for="item in deviceStatusArr"
          :key="item.code"
          :style="{
            background:
              item.code == activeStatus ? item.activeColor : '#e6e7f0',
            color: item.code == activeStatus ? '#fff' : '#98a1cb',
          }"
          @click="statusClick(item)"
        >
          <span class="status-text">{{ item.name }}</span>
          <span class="status-num">{{ item.num }}</span>
        </div>
      </div>
    </div>
    <!-- 列表展示 -->
    <list
      ref="list"
      :active-tab="activeTab"
      :active-status="activeStatus"
      @initList="(list) => $emit('getInitView', list)"
    ></list>
  </div>
</template>

<script>
import List from "./List.vue";
import {
  apiGetCategoryAndStatus,
  apiGetDeviceNum,
  apiGetWaterRunningStateCount,
} from "@/api/runBoard.js";
import { activeColorObj } from "../const.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CategoryTab",
  components: { List },
  props: {},
  data() {
    return {
      activeTab: null,
      categoryTab: [],
      activeStatus: "0",
      deviceStatusArr: [],
    };
  },

  computed: {
    ...mapGetters({
      websocket: "alarmPush/websocket",
      boardUpdate: "alarmPush/boardUpdate",
      userInfo: "userInfo",
    }),
  },

  watch: {
    boardUpdate(val) {
      if (val) {
        // 判断当前是否处于综合监控-告警
        if (this.activeTab == "0" && this.activeStatus == "alarm") {
          this.$refs.list.showList = true;
          this.$refs.list.alarmTypeSearch = "";
          this.$refs.list.alarmCreateTimeFlag = 1;
          this.$refs.list.getDeviceList(1);
        } else {
          this.activeTab = "0";
          this.activeStatus = "alarm";
        }
        this.$store.commit("alarmPush/SET_BOARD_UPDATE", false);
      }
    },
  },
  mounted() {
    this.getCategoryAndStatus();
    if (this.$has("monitor:run:dashboard") && !this.websocket) {
      this.initWebSocket();
    }
  },

  methods: {
    ...mapActions({
      initWebSocket: "alarmPush/initWebSocket",
      clearWebSocket: "alarmPush/clearWebSocket",
    }),

    getCategoryAndStatus() {
      apiGetCategoryAndStatus().then((res) => {
        this.categoryTab = res.firstCategory || [];
        this.deviceStatusArr = (res.runningState || []).map((item) => {
          return {
            ...item,
            activeColor: activeColorObj[item.code],
            num: "--",
          };
        });
        this.activeTab = this.categoryTab[0]?.code;
        this.getDeviceNum();
      });
    },

    categoryClick(item) {
      this.activeTab = item.code;
      this.activeStatus = "0";
      this.getDeviceNum();
    },

    statusClick(item) {
      this.activeStatus = item.code;
    },

    getDeviceNum() {
      const tenantType = this.userInfo.tenantType;
      const API =
        tenantType === "0" ? apiGetDeviceNum : apiGetWaterRunningStateCount;
      const searchParams = {
        firstCategory: tenantType === "0" ? this.activeTab : undefined,
        userType: tenantType === "1" ? this.activeTab : undefined,
      };
      API(searchParams).then((res) => {
        let numArr = res.runningState || {};
        this.deviceStatusArr = this.deviceStatusArr.map((item) => {
          return {
            ...item,
            num: item.code == "0" ? numArr["all"] : numArr[item.code],
          };
        });
      });
    },
  },

  beforeDestroy() {
    this.clearWebSocket();
  },
};
</script>
<style lang="scss" scoped>
.category-tab {
  min-width: 277px;
  max-height: 450px;
  background: #fff;
  position: absolute;
  top: 88px;
  left: 20px;
  border-radius: 8px;
  padding: 30px 20px;
  display: flex;
  .first-category,
  .device-status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
  .first-category {
    padding: 0 27px 0 10px;
    border-right: 1px dashed #737580;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .icon-box {
        width: 46px;
        height: 46px;
        background: #e6e7f0;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 30px;
          color: #98a1cb;
        }
      }
      .category-text {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 21px;
        margin-top: 8px;
      }
    }
    &-item.active {
      .icon-box {
        background: rgba(77, 107, 255, 0.12);
        i {
          color: #4d6bff;
        }
      }
      .category-text {
        color: #4d6bff;
      }
    }
    .first-category-item + .first-category-item {
      padding-top: 30px;
    }
  }
  .device-status {
    padding-left: 20px;
    flex: 1;
    &-item {
      width: 100%;
      height: 54px;
      line-height: 54px;
      border-radius: 4px;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .status-text {
        font-size: 14px;
        line-height: 21px;
      }
      .status-num {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;

        padding-left: 20px;
      }
    }
  }
}
</style>

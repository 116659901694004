<template>
  <div
    class="info-box"
    v-loading.fullscreen.lock="loading"
    :style="infoWindowStyle"
  >
    <div class="device-list" v-if="currentPosList.length > 1" ref="device-list">
      <div class="device-list-title" ref="device-list-title">
        <span>设备列表</span>
        <i class="el-icon-close" @click="closeList('list')"></i>
      </div>
      <ul class="list-box">
        <li
          v-for="item in currentPosList"
          :key="item.iotDeviceId"
          @click="handleMultipleClick(item)"
          :class="[
            'list-item',
            cardItem.iotDeviceId == item.iotDeviceId ? 'active' : '',
          ]"
          :ref="'list-item' + item.iotDeviceId"
        >
          <div class="list-item-left">
            <div class="list-item-left-info">
              <i class="iconfont icon-shebeibianhao1"></i>
              <span>{{ !isBlank(item.deviceNo) ? item.deviceNo : "--" }}</span>
            </div>
            <div class="list-item-left-info">
              <i class="iconfont icon-shebeidizhi"></i>
              <span>{{ !isBlank(item.address) ? item.address : "--" }}</span>
            </div>
          </div>
          <div class="list-item-right">
            <span
              class="device-tag"
              :style="{
                background: activeColorObj[item.runningState],
              }"
              >{{ transStatus(item.runningState) }}</span
            >
            <i class="el-icon-arrow-right"></i>
          </div>
        </li>
      </ul>
    </div>
    <div
      :class="[
        'device-card',
        deviceDetail.runningState,
        currentPosList.length > 1 ? 'have-parent-list' : 'no-parent-list',
      ]"
      :style="{ top: top + 'px' }"
      v-if="showCard"
    >
      <div v-if="Object.keys(deviceDetail).length > 0">
        <div
          class="device-card-top"
          :style="{
            background: activeColorObj[deviceDetail.runningState],
          }"
        >
          <div class="device-name">
            <div class="device-name-left">
              <div class="icon-box">
                <i
                  class="iconfont"
                  :class="[transIcon(deviceDetail.firstCategory)]"
                ></i>
              </div>
              <span class="name">{{
                !isBlank(deviceDetail.deviceName)
                  ? deviceDetail.deviceName
                  : "--"
              }}</span>
              <span
                class="device-tag"
                :style="{
                  color: activeColorObj[deviceDetail.runningState],
                }"
                >{{ transStatus(deviceDetail.runningState) }}</span
              >
            </div>
            <div class="device-name-right">
              <i class="el-icon-close" @click="closeList('card')"></i>
            </div>
          </div>
          <div class="spec-info-box">
            <div class="spec-info">
              <div class="info-item">
                <i class="iconfont icon-shebeibianhao1"></i>
                <span class="value">{{
                  !isBlank(deviceDetail.deviceNo) ? deviceDetail.deviceNo : "--"
                }}</span>
              </div>
              <div class="info-item">
                <i class="iconfont icon-zhandianmingcheng"></i>

                <span class="value">{{
                  !isBlank(deviceDetail.userName) ? deviceDetail.userName : "--"
                }}</span>
              </div>
              <div class="info-item">
                <i class="iconfont icon-shebeidizhi"></i>
                <span class="value">
                  {{
                    !isBlank(deviceDetail.address) ? deviceDetail.address : "--"
                  }}
                </span>
              </div>
            </div>
            <div class="device-name-right">
              <el-button
                @click="jumpDetail(deviceDetail)"
                :style="{
                  color: activeColorObj[deviceDetail.runningState],
                }"
                >查看详情</el-button
              >
            </div>
          </div>
        </div>
        <div class="device-card-bottom">
          <div class="real-data">
            <i class="iconfont icon-shishishuju"></i>
            <span>实时数据</span>
          </div>
          <div
            class="field-info"
            v-if="
              deviceDetail.runningData && deviceDetail.runningData.length > 0
            "
          >
            <div
              class="field-value"
              v-for="item in deviceDetail.runningData"
              :key="item.attributeKey"
              :class="{
                'data-time': item.attributeKey === 'communicationTime',
              }"
            >
              <span class="field">{{ item.name }}：</span>
              <span>{{ transRunningData(item) }}</span>
            </div>
          </div>
          <div class="field-info-empty" v-else>暂无数据</div>
        </div>
      </div>
      <div class="device-card-empty" v-else>
        <i class="el-icon-close" @click="closeList('card')"></i>
        <img class="no-data-img" src="@/assets/images/pic/empty.svg" />
        <span>暂无设备详情数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetDeviceDetail } from "@/api/runBoard.js";
import { isBlank } from "@/utils/validate.js";
import { activeColorObj } from "../const.js";

export default {
  name: "InfomationForm",
  props: {
    currentPosList: {
      required: false,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      activeColorObj,
      showCard: true,
      cardItem: "", //当前展示的设备
      top: 0, //当存在列表时，设备卡片的定位
      deviceDetail: {},
      loading: false,
    };
  },
  computed: {
    infoWindowStyle() {
      if (this.currentPosList.length > 1) {
        return {
          left: "-150px",
          top: "45px",
        };
      } else {
        return {
          left: "-300px",
          bottom: "7px",
        };
      }
    },
  },
  watch: {
    currentPosList: {
      handler(val) {
        if (val.length > 1) {
          //当前坐标点存在多个设备
          this.showCard = false;
        }
        this.cardItem = "";
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    isBlank,
    // 列表点击
    handleMultipleClick(item) {
      this.showCard = false;
      this.getDeviceDetail(item.iotDeviceId);
      this.cardItem = item;
      let activeItemRef = this.$refs["list-item" + item.iotDeviceId][0];
      this.top =
        activeItemRef.getBoundingClientRect().top -
        this.$refs["device-list"].getBoundingClientRect().top;
    },
    // 设备详情
    getDeviceDetail(iotDeviceId) {
      this.showCard = false;
      this.deviceDetail = {};
      return new Promise((resolve) => {
        this.loading = true;
        apiGetDeviceDetail({
          iotDeviceId,
        })
          .then((res) => {
            this.deviceDetail = res || {};
            this.showCard = true;
          })
          .finally(() => {
            this.loading = false;
            resolve();
          });
      });
    },
    // 设备状态转换
    transStatus(state) {
      return (
        (
          (this?.$parent?.$parent.$refs?.category?.deviceStatusArr || []).find(
            (item) => item.code === state
          ) || {}
        ).name || "--"
      );
    },
    // 设备icon转换
    transIcon(code) {
      return (
        (
          (this?.$parent?.$parent.$refs?.category?.categoryTab || []).find(
            (item) => item.code === code
          ) || {}
        ).icon || "icon-zonghejiankong"
      );
    },
    // 实时数据转换
    transRunningData(item) {
      if (!isBlank(item.value)) {
        let result = "";
        // 枚举
        if (item.valueType == 2) {
          result = item.interactiveTranslationDes[item.value];
        } else {
          result = item.value;
        }
        return result + (!isBlank(item.unit) ? item.unit : "");
      } else {
        return "--";
      }
    },
    // 跳转设备详情
    jumpDetail(item) {
      this.$router.push({
        name: "deviceDetail",
        query: {
          deviceId: item.deviceId,
          deviceType: item.deviceTypeId,
        },
      });
    },
    // 关闭信息窗体
    closeList(flag) {
      this.showCard = false;
      this.cardItem = "";
      if (flag === "list") {
        this.$emit("closeList");
      } else {
        if (this.currentPosList.length == 1) {
          this.$emit("closeList");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.info-box {
  position: absolute;
  .icon-box {
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    text-align: center;
    line-height: 24px;
    color: #fff;
    font-size: 16px;
  }
  .spec-info {
    padding-left: 30px;
    .info-item {
      padding-top: 9px;
      display: flex;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      i {
        padding-right: 5px;
        font-size: 14px;
      }
    }
  }

  .device-list {
    width: 295px;
    background: #fff;
    border-radius: 8px;
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #7990ff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 12px 12px 16px 12px;
      color: #fff;
      i {
        padding: 3px 0 3px 5px;
        cursor: pointer;
      }
    }
    .list-box {
      overflow: auto;
      max-height: 400px;
      position: relative;
      .list-item {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
        &-right {
          flex-shrink: 0;
          .device-tag {
            padding: 3px 5px;
            border-radius: 15px;
            font-size: 12px;
            color: #fff;
          }
          i {
            padding: 5px 0 5px 10px;
            cursor: pointer;
            color: #6b76ac;
          }
        }
        &.active {
          background: #ebeeff;
          .list-item-left {
            color: #7990ff;
          }
        }
        .list-item-left {
          padding-right: 8px;
          &-info {
            i {
              color: #6b7399;
              padding-right: 3px;
            }
            span {
              display: inline-block;
              word-break: break-all;
            }
            display: flex;
            line-height: 21px;
          }
        }
      }
      .list-item + .list-item {
        border-top: 1px dashed #cccccc;
      }
    }
    &::after {
      content: "";
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid #7990ff;
      position: absolute;
      top: -10px;
      left: 55%;
      transform: rotate(90deg);
    }
  }
}
.device-card.have-parent-list {
  position: absolute;
  left: 310px;
  top: 0;
  &::after {
    content: "";
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #fff;
    position: absolute;
    bottom: 80%;
    left: -7px;
    transform: rotate(0deg);
  }
  &.normal::after {
    border-right: 7px solid #29c287;
  }
  &.offline::after {
    border-right: 7px solid #afb4cc;
  }
  &.alarm::after {
    border-right: 7px solid #ff9419;
  }
  &.fault::after {
    border-right: 7px solid #e5662e;
  }
}

.device-card {
  width: 456px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  &-top {
    padding: 10px 16px 16px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    .device-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
        display: flex;
        align-items: center;
        .icon-box {
          i {
            font-size: 18px;
          }
        }
        .name {
          padding-left: 6px;
          padding-right: 8px;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
        }
        .device-tag {
          padding: 3px 5px;
          border-radius: 15px;
          background: #fff;
          font-size: 12px;
          color: #29c287;
        }
      }
      &-right {
        color: #fff;
        i {
          padding: 5px 0 5px 5px;
          cursor: pointer;
        }
      }
    }
    .spec-info-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .device-name-right {
        padding-right: 20px;
        .el-button {
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          border-radius: 16px;
          background: #fff;
        }
      }
    }
  }
  &-bottom {
    padding: 20px 16px 16px 46px;
    .real-data {
      color: #858894;
      font-size: 14px;
      i {
        padding-right: 5px;
      }
    }
    .field-info {
      color: #666666;
      padding-top: 8px;
      line-height: 21px;
      display: flex;
      flex-wrap: wrap;
      .field-value {
        width: 50%;
        &.data-time {
          width: 100%;
        }
      }
    }
    .field-info-empty {
      text-align: center;
      padding-top: 20px;
      color: #666666;
    }
  }
  &::after {
    content: "";
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #fff;
    position: absolute;
    bottom: -10px;
    left: 67%;
    transform: rotate(-100deg);
  }
  &-empty {
    text-align: center;
    padding: 30px 0;
    img {
      width: 100px;
    }
    span {
      display: block;
      color: #5c6073;
      padding-top: 20px;
    }
    i {
      padding: 0 0 10px 20px;
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }
  }
}
</style>
